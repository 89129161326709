<template>
    <div id="produce-header">
        <div class="logo">
            <img width="70px" height="37px" src="../../../assets/images/u75.png" alt="logo" />
        </div>
        <div class="name">创作者中心</div>
        <!-- <div class="user-wrapper"> -->
            <div class="user" @click="handleDropdownChange" @mouseleave="handleMouseLeaveLogo">
                <userLogoComponent width="22" height="22" :disabled-hover="true" :user="{ avatar: userInfo.avatar }" />
                <div class="user-name">
                    {{ userInfo.nickname }}
                </div>
                <div :class="['user-dropdown', active ? 'active' : '']" @click.stop @mouseenter="handleMouseEnterLogo" @mousedown="handleMouseLeaveLogo">
                    <div class="user-dropdown-user" @click.stop="handleViewMine">
                        <userLogoComponent width="24" height="24" :disabled-hover="true" :user="{ avatar: userInfo.avatar }" />
                        <span class="user-name">{{ userInfo.nickname }}</span>
                    </div>
                    <div class="produce">
                        <userEdit />
                        <span>创作者中心</span>
                    </div>
                    <div class="logout" @click="handleLogout">
                        <logout />
                        <span>退出</span>
                    </div>
                </div>
            </div>
        <!-- </div> -->
    </div>
</template>
<script>
    import userLogoComponent from '../../UserLogo/index.vue';
    import { logout, userEdit } from '../../../core/icon';
    export default {
        name: 'ProduceHeader',
        components: {
            userLogoComponent,
            logout,
            userEdit,
        },
        data () {
            return {
                active: false,
                timeout: null,
            }
        },
        activated () {
            // console.log('ProduceHeader, activated');
        },
        computed: {
            userInfo(){
                return this.$store.state.userInfo;
            }
        },
        methods: {
            handleDropdownChange(){
                this.active = !this.active;
                if (this.timeout) window.clearTimeout(this.timeout);
            },
            handleMouseEnterLogo(){
                if (this.timeout) window.clearTimeout(this.timeout);
                this.active = true;
            },
            handleMouseLeaveLogo(){
                return
                if (this.timeout) window.clearTimeout(this.timeout);
                this.timeout = window.setTimeout(() => {
                    this.active = false;
                }, 2000)
            },
            handleLogout(){
                this.$store.commit('setToken', '');
                this.$store.commit('setUserInfo', { id: '' });
                localStorage.removeItem('token', '');
            },
        }
    }
</script>
<style lang="less">
    #produce-header{
        width: 100%;
        height: 60px;
        z-index: 99;
        background-color: #058e44;
        position: fixed;
        top: 0;
        padding: 0 calc( 50% - 600px );
        display: flex;
        align-items: center;
        &>.name{
            flex-grow: 1;
            padding: 0 0 0 30px;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
        }
        &>.user{
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            &>.user-name{
                font-size: 15px;
                color: #fff;
                padding: 0 14px 0 10px;
            }
            &>.user-dropdown{
                display: flex;
                flex-flow: column nowrap;
                align-items: stretch;
                width: 160px;
                // min-height: 120px;
                background-color: #fff;
                box-shadow: 0 1px 3px 0 rgba(0,0,0, 0.3);
                position: absolute;
                left: -24px;
                transform: translateY(-100%);
                transition: all 0.35s ease-in-out;
                opacity: 0;
                &.active{
                    opacity: 1;
                    transform: translateY(80px);
                }
                &>div{
                    height: 30px;
                    margin: 10px 0;
                    padding: 0 14px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    color: #33353c;
                    &:hover{
                        color: #058e44;
                    }
                    &.user-dropdown-user{
                        &>img{
                            width: 24px;
                            height: 24px;
                        }
                    }
                    &>svg{
                        width: 20px;
                        height: 20px;
                    }
                    &>span{
                        width: 108px;
                        text-align: left;
                        
                        font-size: 14px;
                        padding: 0 0 0 10px;
                    }
                }
            }
        }
    }
</style>
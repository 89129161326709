<template>
    <div id="produceLayout">
        <ProduceHeader />
        <div class="container">
            <el-menu v-model="path" default-active="/produce" class="el-menu-vertical-demo">
                <el-menu-item index="/produce">
                    <template slot="title">
                        <i class="el-icon-s-home"></i>
                        <span>首页</span>
                    </template>
                </el-menu-item>
                <el-submenu index="/produce/write">
                    <template slot="title">
                        <i class="el-icon-edit"></i>
                        <span>发布</span>
                    </template>
                    <el-menu-item index="/produce/write">发布长文</el-menu-item>

                </el-submenu>
            </el-menu>
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import ProduceHeader from './Header.vue';
    import Footer from '../../Footer/index.vue';
    export default {
        name: 'ProduceLayout',
        components: {
            ProduceHeader,
            Footer,
        },
        data () {
            return {
                
            }
        },
        activated () {
            // console.log('ProduceLayout, activated');
        },
        computed: {
            path: {
                get() {
                    return this.$route.path;
                },
                set(){

                }
            }
        }
    }
</script>
<style lang="less">
    @import './index.less';
</style>